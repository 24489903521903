.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  text-align: center;
}

.white {
  color: white;
}

.black {
  color: black;
}